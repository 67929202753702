<template>
    <div>
        <router-view :nav-is-opened="navIsOpened"></router-view>

        <div class="convo-list-header">
            <PageHeading :breadcrumbs="breadcrumbs" title="Convos" />
            <Button has-border size="small" @click="onChannelEdit">Edit channel</Button>
        </div>

        <FullPageLoadingSpinner :show="$wait.is(LoadingFlag.ConvoGet)" />

        <div class="convo-list-search m-b-2">
            <Input
                id="search"
                ref="searchTerm"
                v-model="searchTerm"
                type="text"
                name="search"
                label="Search convo"
                placeholder="Search for a convo here"
                class="w-25"
                :has-margin-bottom="false"
                :has-margin-top="true"
                :show-clear-icon="true"
                default-icon="magnifying-glass"
                @input="searchConvo"
            />
        </div>

        <div class="datatable">
            <Datatable
                :data="convoListView.convoList"
                :columns="tableColumns"
                :filters="tableFilters"
                @filterClick="filterConvo"
            >
                <template v-slot:status="slotProps">
                    <Badge v-if="slotProps.item.published" type="success">published</Badge>
                    <Badge v-else type="neutral">unpublished</Badge>
                </template>

                <template v-slot:chatGroup="{ item }">
                    <span v-if="item.chatGroup === ChatGroup.Editorial">Editorial</span>
                    <span v-else-if="item.chatGroup === ChatGroup.Global">Global Chat</span>
                </template>

                <template v-slot:actions="{ item }">
                    <TableRowActions
                        :button-actions="buttonActions"
                        :menu-actions="menuActions"
                        @action="(action) => handleAction(action, item)"
                    />
                </template>
            </Datatable>
        </div>

        <Paging
            :total="convoListView.convoList.total"
            :size="convoListView.convoList.size"
            :page="convoListView.convoList.page"
            @pageSelected="selectPage"
        />

        <Stepper :nav-is-opened="navIsOpened" :is-loading="false">
            <DropDownButton
                :drop-down-items="convoTypesDropdownList"
                @click-dropdown-item="onClickDropDownItem"
            >
                New Convo
            </DropDownButton>
        </Stepper>

        <CloneConvo
            :show="cloneConvo.show"
            :is-loading="false"
            :searching-is-loading="searchingIsLoading"
            :search-options="contentOptions"
            :channel-code="cloneConvo.channelCode"
            @searchChannels="searchChannels"
            @channelSelected="clearSearchResultOptions"
            @close="cloneConvoOnClose"
            @confirm="cloneConvoOnConfirm"
        />
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import Datatable from '../components/Datatable.vue';
import Paging from '../components/Paging';
import FullPageLoadingSpinner from '../components/ui/FullPageLoadingSpinner';
import Input from '@/components/forms/Input';
import Stepper from '@/components/ui/Stepper';
import { router } from '@/router';
import { LoadingFlag, ChatGroup } from '@/store/enums';
import convoTypes from '@/json/convoTypes';
import CloneConvo from '@/components/CloneConvo';
import Badge from '@/components/ui/Badge';
import DropDownButton from '@/components/ui/DropDown/DropDownButton';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import { ContentClient } from '@/api/content.ts';
import TableRowActions from '@/components/TableRowActions';
import { getFinalReportData } from '@/store/services/reportService';
import { downloadCSV } from '@/utils/downloadCsv';
import config from '@/config';
import PageHeading from '@/components/ui/PageHeading';
import Button from '@/components/ui/Button';

export default {
    name: 'ConvoList',
    components: {
        PageHeading,
        Datatable,
        DropDownButton,
        Paging,
        Input,
        FullPageLoadingSpinner,
        Stepper,
        TableRowActions,
        CloneConvo,
        Badge,
        Button,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            LoadingFlag,
            searchTerm: '',
            breadcrumbs: [],
            cloneConvo: {
                show: false,
                channelCode: null,
                convoId: null,
            },
            buttonActions: [
                {
                    label: 'Publish Convo',
                    action: 'publish',
                    icon: 'actions-publish',
                },
                {
                    label: 'Broadcast',
                    action: 'broadcast',
                    icon: 'actions-broadcast',
                    iconSize: '16px',
                    disabled: true,
                },
                {
                    label: 'View Convo Report',
                    action: 'view-report',
                    icon: 'actions-reports',
                    iconSize: '13px',
                },
            ],
            menuActions: [
                {
                    label: 'View on Messenger',
                    action: 'view-messenger',
                    icon: 'actions-view-messenger',
                },
                {
                    label: 'Download Report',
                    action: 'download-report',
                    icon: 'actions-download',
                },
                {
                    label: 'Clone Convo',
                    action: 'clone',
                    icon: 'clone-convo',
                },
                {
                    label: 'Edit Convo',
                    action: 'edit',
                    icon: 'actions-edit',
                },
            ],
            tableColumns: [
                {
                    value: 'name',
                    header: 'CONVO NAME',
                    type: 'text',
                    width: '30%',
                },
                {
                    value: 'type',
                    header: 'TYPE',
                    type: 'text',
                    width: '15%',
                },
                {
                    value: 'createdAt',
                    header: 'CREATED ON',
                    type: 'date',
                    width: '20%',
                    format: config.dateFormat,
                },
                {
                    value: 'chatGroup',
                    header: 'CHAT GROUP',
                    type: 'slot',
                    width: '13%',
                },
                {
                    value: 'status',
                    header: 'STATUS',
                    type: 'slot',
                    width: '10%',
                },
                {
                    value: 'actions',
                    header: '',
                    type: 'slot',
                    width: '12%',
                    class: 'table-row-actions',
                },
            ],
        };
    },
    computed: {
        ...mapGetters('convoList', ['convoListView']),
        ...mapGetters('channel', {
            channelDetails: 'channelDetails',
        }),
        ...mapGetters('convoDetails', {
            convoDetailsView: 'convoDetailsView',
        }),
        ...mapState('reports', ['reports']),
        ...mapState('tenant', ['searchingIsLoading', 'contentOptions']),
        tableSelectedFilters() {
            if (this.selectedFilters) {
                return this.selectedFilters;
            } else {
                return {
                    type: -1,
                    status: -1,
                    chatGroup: -1,
                };
            }
        },
        convoTypes() {
            return convoTypes.map((o) => ({ value: o.name, title: o.title }));
        },
        convoTypesDropdownList() {
            return convoTypes.filter((convoType) => !convoType.hidden);
        },
    },
    async created() {
        this.tableFilters = {
            type: {
                selected: -1,
                items: this.convoTypes,
            },
            status: {
                selected: -1,
                items: [
                    { title: 'Published', value: 1 },
                    { title: 'Unpublished', value: 0 },
                ],
            },
            chatGroup: {
                selected: -1,
                items: [
                    { title: 'Editorial', value: 1 },
                    { title: 'Global Chat', value: 2 },
                ],
            },
        };

        this.ChatGroup = ChatGroup;
        this.getConvos({});
        await this.setChannelDetails();
        await this.setBreadcrumbs();
        this.clearSearchResultOptions();
    },
    methods: {
        ...mapActions('convoList', ['getConvos']),
        ...mapActions('convoDetails', ['setConvoType']),
        ...mapActions('channel', ['setChannelDetails']),
        ...mapActions('reports', ['getReport']),
        ...mapActions('tenant', {
            searchContent: 'searchContent',
            clearSearchResultOptions: 'clearSearchResultOptions',
        }),
        async downloadReport(convo) {
            await this.getReport({ convoId: convo.id });
            const reportData = getFinalReportData(this.reports);
            const filename = convo.name + '_aggregated';
            const title = `Convo Name: ${convo.name}`;
            downloadCSV(filename, title, reportData);
        },
        handleAction(action, item) {
            const channelCode = this.channelDetails.channel.code;
            const convoId = item.id;
            const params = { channelCode, convoId };

            const actionMap = {
                publish: () => this.$router.push({ name: 'publishing', params }),
                edit: () => this.$router.push({ name: 'messages', params }),
                clone: this.cloneConvoOnClick.bind(this, item),
                'view-report': () => this.$router.push({ name: 'reports', params }),
                'download-report': this.downloadReport.bind(this, item),
                'view-messenger': () =>
                    window.open(`${config.baseUrl}${item.path}?restart=1`, '_blank'),
            };
            if (!actionMap[action]) {
                this.$log.warn('no handler for action');
                return;
            }
            actionMap[action]();
        },
        searchChannels(query) {
            this.searchContent({ type: 'channels', query, includeInactive: true });
        },
        async selectPage(event, page) {
            const options = {
                q: this.searchTerm,
                page: page,
                convoTypes: [this.tableFilters['type'].selected],
                status: this.tableFilters['status'].selected,
                chatGroup: this.tableFilters['chatGroup'].selected,
            };
            this.getConvos(options);
        },
        async searchConvo() {
            const options = {
                q: this.searchTerm,
                page: 1,
                convoTypes: [this.tableFilters['type'].selected],
                status: this.tableFilters['status'].selected,
                chatGroup: this.tableFilters['chatGroup'].selected,
            };
            await this.getConvos(options);
        },
        async filterConvo(value) {
            this.tableFilters[value.column].selected = value.item;
            const options = {
                q: this.searchTerm,
                page: 1,
                convoTypes: [this.tableFilters['type'].selected],
                status: this.tableFilters['status'].selected,
                chatGroup: this.tableFilters['chatGroup'].selected,
            };
            await this.getConvos(options);
        },
        backButtonClick(event) {
            router.push({ name: 'all-channels' });
        },
        async nextButtonClick(event, async) {
            router.push({
                name: 'new-convo',
                params: { channelId: router.currentRoute.params.channelId },
            });
        },
        setBreadcrumbs() {
            const breadcrumbs = [];
            const channelsLink = { name: 'all-channels' };
            breadcrumbs.push(new Breadcrumb('channels', channelsLink));
            breadcrumbs.push(
                new Breadcrumb(this.channelDetails.channel.name, {
                    name: 'convo-list',
                    channelCode: this.channelDetails.channel.code,
                }),
            );
            this.breadcrumbs = breadcrumbs;
        },
        cloneConvoOnClick(item) {
            this.cloneConvo = {
                show: true,
                channelCode: this.channelDetails.channel.code,
                convoId: item.id,
            };
        },
        async cloneConvoOnConfirm(payload) {
            this.clearSearchResultOptions();

            let data;
            if (payload.channelCode !== this.cloneConvo.channelCode) {
                data = {
                    channelCode: payload.channelCode,
                };
            }
            try {
                const newConvo = await ContentClient.cloneConvo(this.cloneConvo.convoId, data);
                this.$router.push({
                    name: 'messages',
                    params: { channelCode: payload.channelCode, convoId: newConvo.id },
                });
            } catch (error) {
                this.cloneConvo = {
                    show: false,
                    channelCode: null,
                    convoId: null,
                };
            }
        },
        cloneConvoOnClose() {
            this.cloneConvo.show = false;
            this.clearSearchResultOptions();
        },
        async onClickDropDownItem(item) {
            this.setConvoType(item.name);
            this.$router.push({
                name: 'create-convo',
                params: {
                    channelCode: this.channelDetails.channel.code,
                },
            });
        },
        onChannelEdit() {
            this.$router.push({
                name: 'channel-detail',
                params: {
                    channelCode: this.channelDetails.channel.code,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.convo-list-search {
    display: flex;
    align-items: center;

    & > div {
        margin-right: 1em;
    }
}

.convo-list-header {
    display: flex;
    justify-content: space-between;
}
</style>
