<template>
    <Overlay :show="show" :show-close-button="false" :transparency="0.8" @onClose="$emit('close')">
        <Panel class="clone-convo">
            <h2 class="panel-title m-b-1">Clone Convo</h2>
            <p class="paragraph--medium">Where would you like to duplicate this convo?</p>
            <div class="clone-convo__form">
                <RadioButtonGroup
                    name="same-channel"
                    is-grouped
                    is-button-styled
                    :has-margin-bottom="false"
                    :options="[
                        { value: 'true', name: 'Same Channel', icon: 'same-channel' },
                        { value: 'false', name: 'Different Channel', icon: 'move-channel' },
                    ]"
                    :value="`${sameChannel}`"
                    @input="sameChannelOnInput"
                />

                <AutoComplete
                    v-if="!sameChannel"
                    class="m-t-2"
                    :items="channelAutoCompleteItems"
                    label="Channel"
                    :placeholder="
                        searchPlaceholder || searchPlaceholder === ''
                            ? searchPlaceholder
                            : 'Type to find Channel'
                    "
                    :value="searchValue"
                    :is-async="true"
                    @input="onInput"
                    @focus="onSearch"
                    @query="onSearch"
                >
                    <template v-slot:default="{ result, resultIndex }">
                        <div v-if="searchOptions[resultIndex]" class="channel-result">
                            {{ searchOptions[resultIndex].text }}

                            <div class="channel-result-badges">
                                <Badge type="neutral" class="channel-result-badge">
                                    {{ searchOptions[resultIndex].code }}
                                </Badge>

                                <Badge
                                    :type="searchOptions[resultIndex].type"
                                    class="channel-result-badge"
                                >
                                    {{ searchOptions[resultIndex].label }}
                                </Badge>
                            </div>
                        </div>
                    </template>
                </AutoComplete>
            </div>
            <div class="text-right">
                <Button
                    :disabled="isLoading"
                    button-style="secondary"
                    size="small"
                    @click="$emit('close', $event)"
                >
                    Cancel
                </Button>
                <Button
                    has-border
                    :is-loading="isLoading"
                    :disabled="isLoading"
                    size="small"
                    @click="onConfirm"
                >
                    Confirm clone
                </Button>
            </div>
        </Panel>
    </Overlay>
</template>

<script>
import Overlay from '@/components/ui/Overlay';
import Panel from '@/components/ui/Panel';
import Button from '@/components/ui/Button';
import RadioButtonGroup from '@/components/forms/RadioButtonGroup';
import AutoComplete from '@/components/forms/AutoComplete';
import Badge from '@/components/ui/Badge';

export default {
    name: 'CloneConvo',
    components: {
        Badge,
        Overlay,
        Panel,
        Button,
        RadioButtonGroup,
        AutoComplete,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        channelCode: {
            type: String,
            default: null,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        searchOptions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            sameChannel: true,
            cloneChannelCode: null,
            searchPlaceholder: null,
            searchValue: '',
            validated: false,
        };
    },
    computed: {
        channelAutoCompleteItems() {
            return this.searchOptions.map((opt) => opt.text);
        },
        searchChannelErrors() {
            if (this.validated && !this.sameChannel && !this.cloneChannelCode) {
                return ['Channel is required.'];
            }
            return [];
        },
    },
    methods: {
        onInput(channelName, channelIndex, optionIndex) {
            const channel = this.searchOptions[optionIndex];
            if (channel) {
                this.searchValue = channelName;
                this.cloneChannelCode = channel.code;
                this.$emit('channelSelected');
            }
        },
        onSearch(query) {
            this.searchValue = query;
            this.$emit('searchChannels', query);
        },
        reset() {
            this.searchPlaceholder = null;
            this.searchValue = '';
            this.validated = false;
            this.cloneChannelCode = null;
        },
        onConfirm() {
            this.validated = true;

            if (!this.searchChannelErrors.length) {
                let channelCode = this.channelCode;
                if (!this.sameChannel) {
                    channelCode = this.cloneChannelCode;
                }
                this.reset();
                this.$emit('confirm', { channelCode });
            }
        },
        sameChannelOnInput(value) {
            this.reset();
            this.sameChannel = value === 'true' ? true : false;
        },
    },
};
</script>

<style lang="scss" scoped>
.clone-convo {
    @include center();
    max-width: 500px;
    width: 100%;

    &__form {
        max-width: 400px;
        margin: 10px auto 40px;
    }
}

.channel-result,
.channel-result-badges {
    display: flex;
    align-items: center;
}

.channel-result {
    justify-content: space-between;
}

.channel-result-badge {
    margin-left: 2px;
}
</style>
