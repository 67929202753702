var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Overlay',{attrs:{"show":_vm.show,"show-close-button":false,"transparency":0.8},on:{"onClose":function($event){return _vm.$emit('close')}}},[_c('Panel',{staticClass:"clone-convo"},[_c('h2',{staticClass:"panel-title m-b-1"},[_vm._v("Clone Convo")]),_c('p',{staticClass:"paragraph--medium"},[_vm._v("Where would you like to duplicate this convo?")]),_c('div',{staticClass:"clone-convo__form"},[_c('RadioButtonGroup',{attrs:{"name":"same-channel","is-grouped":"","is-button-styled":"","has-margin-bottom":false,"options":[
                    { value: 'true', name: 'Same Channel', icon: 'same-channel' },
                    { value: 'false', name: 'Different Channel', icon: 'move-channel' } ],"value":("" + _vm.sameChannel)},on:{"input":_vm.sameChannelOnInput}}),(!_vm.sameChannel)?_c('AutoComplete',{staticClass:"m-t-2",attrs:{"items":_vm.channelAutoCompleteItems,"label":"Channel","placeholder":_vm.searchPlaceholder || _vm.searchPlaceholder === ''
                        ? _vm.searchPlaceholder
                        : 'Type to find Channel',"value":_vm.searchValue,"is-async":true},on:{"input":_vm.onInput,"focus":_vm.onSearch,"query":_vm.onSearch},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var result = ref.result;
                        var resultIndex = ref.resultIndex;
return [(_vm.searchOptions[resultIndex])?_c('div',{staticClass:"channel-result"},[_vm._v(" "+_vm._s(_vm.searchOptions[resultIndex].text)+" "),_c('div',{staticClass:"channel-result-badges"},[_c('Badge',{staticClass:"channel-result-badge",attrs:{"type":"neutral"}},[_vm._v(" "+_vm._s(_vm.searchOptions[resultIndex].code)+" ")]),_c('Badge',{staticClass:"channel-result-badge",attrs:{"type":_vm.searchOptions[resultIndex].type}},[_vm._v(" "+_vm._s(_vm.searchOptions[resultIndex].label)+" ")])],1)]):_vm._e()]}}],null,false,323717326)}):_vm._e()],1),_c('div',{staticClass:"text-right"},[_c('Button',{attrs:{"disabled":_vm.isLoading,"button-style":"secondary","size":"small"},on:{"click":function($event){return _vm.$emit('close', $event)}}},[_vm._v(" Cancel ")]),_c('Button',{attrs:{"has-border":"","is-loading":_vm.isLoading,"disabled":_vm.isLoading,"size":"small"},on:{"click":_vm.onConfirm}},[_vm._v(" Confirm clone ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }